<template>
  <section>
    <!-- LANDING -->
    <div class="home pt-5">
      <div class="container">
        <h1 class="content-header">Welcome to this section on academic resilience</h1>
        <!-- CONTENT -->
        <div class="content-wrapper">
          <div class="row">
            <div class="col-sm order-sm-1">
              <button class="btn" @click="openPopup = true">
                <img
                  src="@/assets/img/_content/academic-landing-lightbox.png"
                  class="img-fluid"
                  alt="Bio"
                >
              </button>
            </div>
            <div class="col-sm">
              <div class="row align-items-center">
                <!-- <div class="col-sm">
                  <img
                    src="@/assets/img/_content/personal-learn-1.jpg"
                    class="img-thumb img-fluid"
                    alt=""
                  >
                </div> -->
                <div class="col-sm">
                  <h2 class="pt-4">Meet Deena</h2>
                  <p class="pt-2"><strong class="bold">Deena Kara Shaffer</strong>, PhD, MEd, BEd, (Hons)BA, OCT is the Coordinator of Student Transitions & Retention in Student Wellbeing, Adjunct Faculty in the Sociology Department, and Lecturer in the Psychology Department, at Toronto Metropolitan University. Deena is the President of the Learning Specialists Association of Canada (2nd term). A former learning strategist for students with disabilities, and a skilled certified high school teacher, Deena offers an inclusive, equity-guided, research-driven, health-promoting, holistic pedagogical stance to every educational encounter. Deena is co-initiator of the <a
                    href="https://www.ryerson.ca/thriveru/tia/"
                    target="_blank"
                  >Thriving in Action</a> resilience intervention, <a
                    href="https://www.ryerson.ca/thriveru/outdoor-active-wellbeing/"
                    target="_blank"
                  >Portage</a> paddling program, and <a
                    href="https://www.facebook.com/rumoodroutes"
                    target="_blank"
                  >Mood Routes</a> campus outdoor st/rolling initiative. Deena holds a doctorate in nature-based pedagogy and learning strategies; is a trained yoga teacher (200 hr), restorative yoga teacher (60 hr), and mindfulness meditation teacher; is published poet (The Grey Tote, Véhicule Press, 2013); and is a thought leader, writer, and public speaker on learning and well-becoming. Deena is also the owner of <a
                    href="https://www.awakenedlearning.ca/"
                    target="_blank"
                  >Awakened Learning</a> academic resilience coaching and consulting.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ROUTING NAV -->
      <div
        class="routing-nav"
        id="route02"
      >
        <h2>How would you like to support your students today?</h2>
        <div>
          <router-link to="academic/learn" class="routing-nav__btn routing-nav__btn--large">
            <strong>Learn</strong> about academic resilience
          </router-link>
          <router-link to="academic/discuss" class="routing-nav__btn routing-nav__btn--large">
            <strong>Discuss</strong> resilience with students
          </router-link>
          <router-link to="academic/share" class="routing-nav__btn routing-nav__btn--large">
            <strong>Share</strong> resources with students
          </router-link>
          <router-link to="academic/design" class="routing-nav__btn routing-nav__btn--large">
            <strong>Design</strong> curriculum that promotes resilience
          </router-link>
          <router-link to="academic/review" class="routing-nav__btn routing-nav__btn--large">
            <strong>Review</strong> research by resilience experts
          </router-link>
        </div>
      </div>

      <!-- MODAL -->
      <modal-popup v-if="openPopup" @close="openPopup = false">
        <div class="video-player">
          <!-- video element -->
          <div class="ratio ratio-16x9">
  <iframe
    src="https://www.youtube.com/embed/dz_tIN0uo54?rel=0&cc_load_policy=1"
    title="YouTube video"
    allowfullscreen
  ></iframe>
</div>
          <!-- <vue-plyr>
            <video
              controls
              crossorigin
              playsinline
            >
              <source
                size="720"
                src="https://staging.gevc.ca/centennial/tic/videos/academic/learn/01.mp4"
                type="video/mp4"
              />
              <track
                label="English"
                kind="subtitles"
                srclang="en"
                src="https://staging.gevc.ca/centennial/tic/cc/academic/landing/01.en.vtt"
                default
              >
            </video>
          </vue-plyr> -->
          <div class="accordion">
            <accordion
              headerText="Transcript"
              accordionID="transcript01"
            >
              <p>Hi, I'm Dr. Deena Kara Shaffer. My pronouns are she and her, and it has been a total delight to work on this Thriving in the Classroom project, in particular, my space of academic resilience, and that languaging means something to me around making more freedom for students, putting a whole bunch of stepping stones, skills and capacities and disruptions and provocations along a student's path in order to be in service of wherever a student wants to go. And what continues to drive me in this area, I suppose, is, you know, I'm a teacher and supporter and strategist all to do with learning. </p>
              <p>And so when I'm in a classroom space or any kind of learning space in real life or digital, I want a student to learn something or contemplate or interact or produce something, many things, and the student is trying to integrate and hold and intersect with... they're trying to keep afloat, they're trying to comprehend and make connections. And we just have this encounter between the teaching type person and the learner type person alongside each other and the academic journey. But so much else is there. So much else is there underneath the surface of things. Hidden curriculum is there, all the kinds of assumed ways of doing and being and knowing. </p>
              <p>A whole student's life is there and what pulls at them and the weights that they hold and their wisdoms that they come with. So academic resilience in this work has something to do around naming that and centering that. That a student's struggles along the way, some of them are personal and some of them are systemic. Sometimes students are so harmfully, pushed to the margins. And I want to be part of making truly disruptive and long lasting and excellently welcoming spaces where learning can be healing, where learning can be nourishing, where learning can be joyful. </p>
              <p>So I want to give as many strategies as possible to educators and to students so that there's the possibility of a freer time about things, that there's an acknowledgement of all that lies under the surface that can't possibly all be named. But we can at least recognize that it's there, that so much can interfere or hold back or compress or make impossible learning or bouncing forward or thriving or excelling. And I want to be part of that uplift in learning, really, to be in service of students wherever they're headed, wherever they want to go. </p>
              <p>Thanks.</p>
            </accordion>
          </div>
          <p><a :href="`${publicPath}docs/academic/Meet-deena.pdf`" target="_blank">Open transcript in a new tab</a></p>
        </div>
      </modal-popup>

      <!-- MAIN NAV -->
      <nav class="navbar main-nav">
        <div class="container">
          <router-link class="main-nav__item" to="/students-resilience/community">COMMUNITY</router-link>
          <router-link class="main-nav__item" to="/students-resilience/personal">PERSONAL</router-link>
          <router-link class="main-nav__item" to="/students-resilience/academic">ACADEMIC</router-link>
          <router-link class="main-nav__item" to="/students-resilience/career">CAREER</router-link>
        </div>
      </nav>

    </div>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
import ModalPopup from '@/components/ModalPopup.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation
//   // Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  components: {
    // SidebarMenu,
    Accordion,
    ModalPopup
    // Hooper,
    // Slide,
    // HooperNavigation
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      hooperSettings: {
        itemsToShow: 4,
        trimWhiteSpace: true,
        centerMode: false
      },

      openPopup: false
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
